import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    // this.element.scrollTop = this.element.scrollHeight
    const offset = this.element.scrollHeight
    this.element.scrollTop = offset > 0 ? offset : 0 }
}
